<template>
  <div class="container">
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="col-md-4 mx-auto mt-4">
      <div class="card">
        <div class="card-header header-blue pb-2">
          <h4 class="text-center my-auto">CONNECTEZ-VOUS</h4>
        </div>
        <div class="card-body">
          <form>
            <template>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                        id="basic-addon1">
                    <i class="flaticon flaticon-group"></i>
                  </span>
                </div>
                <input type="email"
                       name="email"
                       v-model="form.email"
                       class="form-control"
                       placeholder="E-mail"
                       aria-describedby="basic-addon1"
                       aria-label="E-mail" />
              </div>
              <span v-if="form.errors().has('email')"
                    v-text="form.errors().get('email')"
                    class="errorMsg"> </span>
              <div class="input-group mt-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                        id="basic-addon1">
                    &#128274;
                  </span>
                </div>
                <input type="password"
                       name="mot_de_passe"
                       v-model="form.mot_de_passe"
                       class="form-control"
                       placeholder="Mot de passe"
                       aria-label=".form-control-lg example"
                       @keyup.enter="submit" />
              </div>
              <div v-if="form.errors().has('mot_de_passe')"
                   class="form-group mb-0">
                <span v-text="form.errors().get('mot_de_passe')"
                      class="errorMsg"> </span>
              </div>
              <div class="form-check form-check-inline mt-3">
                <input class="form-check-input"
                       type="checkbox"
                       id="inlineCheckbox1"
                       value="option1" />
                <label class="form-check-label"
                       for="inlineCheckbox1">Se souvenir de moi </label>
              </div>
              <div class="btn_submit">
                <input type="button"
                       value="CONNEXION"
                       class="btn btn-blue"
                       :disabled="form.empty()"
                       @click="submit" />
              </div>
              <!-- <p class="errormsg">{{errors}}</p> -->
              <div class="bottom_dec">
                <p>
                  <router-link :to="{ path: '/forgotPassword' }"
                               replace>Mot de passe oublié? </router-link>
                </p>
                <!--<a href="#">Vous n'avez pas un compte? ? Enregistrez vous.</a>-->
              </div>
            </template>
          </form>
        </div>
      </div>
    </div>
    <!--<div class="info_b">remarque:si tout les data corresponds accès à son compte</div>-->
  </div>
</template>
<script>
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
import { mapActions, mapGetters, mapMutations } from "vuex"
export default {
  components: {
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    form: form({
      email: "",
      mot_de_passe: ""
    })
      .rules({
        email: "email|min:5|required",
        mot_de_passe: "required|min:5"
      })
      .messages({
        "email.email": "Ce champ doit contenir un email",
        "mot_de_passe.mot_de_passe": "Requiert un minimum de 5 caractères."
      })
  }),
  watch: {
    ["form.data"]: {
      deep: true,
      immediate: false,
      handler: "onFormChange"
    },
    errors() {
      if (this.errors) {
        if (this.errors == "ERR_OAUTH") {
          this.notif.message = "Email ou mot de passe incorrect"
        } else {
          this.notif.message = this.errors + ", Veuillez contacter l'administrateur."
        }
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
        this.setErors("")
      }
    },
    usersDetails() {
      this.$router.push({ path: "dashboard" })
    }
  },
  computed: {
    ...mapGetters(["usersDetails", "errors"])
  },
  methods: {
    ...mapActions(["loginUser"]),
    ...mapMutations(["setErors"]),
    onFormChange() {},
    submit() {
      this.form.validate()
      if (
        !this.form
          .validate()
          .errors()
          .any()
      ) {
        this.loginUser(this.form.data)
      }
    }
  }
}
</script>
